.tooltip{
  position: absolute;
  top: 175%;
  @include centerer(true, false);
  background: white;
  display: none;
  width: 15rem;
  @extend .card-shadow;
  .tooltip-content{
    font-size: .95rem;
    line-height: 1.35rem;
    position: relative;
    padding: .5rem;
    display: block;
    width: 100%;
    font-weight: normal;
    font-family: $body;
    border: 1px solid darken(white, 20%);
    text-align: left;
    &:before{
      @include css-triangle(darken(white, 20%), up, 11px);
      top: -11px;
      left: -1px;
      @include centerer(true, false);
    }
    &:after{
      @include css-triangle(white, up, 10px);
      top: -10px;
      left: 0px;
      @include centerer(true, false);
    }
  }
}
.tooltip-element{
  position: relative;
  &:hover{
    .tooltip{
      display: inline-block;
    }
  }
}
.box-wrapper{
  border: 1px solid black;
  padding: 1rem;
  margin-bottom: 1rem;
}
p{
  sup{
    vertical-align: top;
    line-height: 1.35rem;
  }
}
header{
  z-index: z('site-header');
  border-bottom: 1px solid darken(white, 10%);
  -webkit-box-shadow: 0 1px 6px rgba(0,0,0,.25);
	-moz-box-shadow: 0 2px 6px rgba(0,0,0,.25);
	box-shadow: 0 2px 6px rgba(0,0,0,.25);
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  border-bottom: 4px solid $primary;
  .main-toolbar{
    height: 6em;
    padding: 0 1.5em;
    background-color: white;
    position: relative;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    // justify-content: flex-end;
    width: 100%;
    .logo-wrapper{
      margin-right: auto;
      display: inline-flex;
      flex-direction: column;
      flex-grow: 0;
      // height: 6rem;
      align-items: center;
      justify-content: center;
      img{
        width: 11rem;
      }
      &.lq-logo{
        margin-right: 2rem;
      }
      // margin-left: .5rem;
      // .logo, .logo-s{
      //   flex-grow: 0;
      //   height: 5rem;
      //   background-image: url('/img/logo-lq.png');
      //   background-size: 90%;
      //   background-position: left center;
      //   background-repeat: no-repeat;
      //   @include mq('tablet-small'){
      //     background-size: 70%;
      //   }
      // }
      // .logo-s{
      //   background-image: url('/img/logo-s.png');
      // }
      span{
        // flex: 1 100%;
        width: 100%;
        text-align: left;
        display: block;
        font-size: 90%;
        margin-top: .25rem;
      }

    }

    @include mq('tablet'){
      height: 4em;
      padding-left: .75rem;
      padding-right: .75rem;
      .logo-wrapper img{
        width: 7.5rem;
      }
    }
    .nav-wrapper{
      margin-left: auto;
      width: auto;
      justify-content: right;
      text-align: right;
      flex-grow: 0;
    }
    nav{
      font-size: 1.35em;
      font-family: $headline;
      align-self: flex-end;
      width: 100%;
      ul li{
        text-transform: none;
      }
      &.info-nav{
        font-size: .8rem;
        align-self: flex-start;
        margin-bottom: 1.5rem;
        ul li{
          text-transform: none;
          a{
            color: lighten(black, 50%);
          }
        }
      }
      sup{
        font-size: .8rem;
        vertical-align: top;
      }
      .active{
        border-bottom: 2px solid $primary;
      }
      @include last-child('right', 1.75em);
      @include mq('tablet-wide'){
        display: none;
      }
    }
  }
}
#error{
  .legal-footer, .safety-wrapper{
    display: none;
  }
    #error-section{
        text-align: center;
        font-size: 1.25rem;
        .title{
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: .5rem;
        }
    }
}
h1{
    line-height: 2.5rem;
    margin-bottom: .5rem;
}
.mobile-nav{
  background: darken(white, 5%);
  top: 0;
  padding: 1rem;
  padding-top: 2rem;
  font-size: 1.15em;
  font-weight: bold;
  font-family: $headline;
  border-left: 1px solid darken(white, 20%);
  -webkit-box-shadow: inset 15px 0px 19px -9px rgba(0,0,0,0.15);
  -moz-box-shadow: inset 15px 0px 19px -9px rgba(0,0,0,0.15);
  box-shadow: inset 15px 0px 19px -9px rgba(0,0,0,0.15);
  nav{
    padding: 0;
    width: 100%;
    display: block;
    position: relative;
    font-size: 1.25rem;
    a{
      color: $primary;
    }
    &.info-nav{
      display: block;
      width: 100%;
      font-size: 1rem;
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid darken(white, 40%);
      @include last-child('bottom', 1.25em);
      ul{
        li{
          text-transform: none;
          a{
            color: lighten(black, 50%);
          }
        }
      }
    }
    @include last-child('bottom', 1.25em);
    li{
      margin-bottom: 1rem;
    }
    a{
      // color: black;
      display: block;
    }
  }
}
em{
    sup{
        margin-left: .25rem;
    }
}

.accent{
  color: $primary;
}
.btn{
  font-family: $headline;
  color: white;
  display: inline-flex;
  align-items: center;
  padding: .75rem 1.5rem;
  border-radius: 3px;
  font-size: 1.15em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: darken(white, 50%);
  cursor: pointer;
  span{
    margin-left: .5rem;
  }
  @extend .card-shadow;
  i{
    margin-left: .5em;
  }
  &:hover{
    color: darken(white, 10%) !important;
  }
  &.main{
    background: $secondary;
    &:hover{
      background: darken($secondary, 5%);
    }
  }
}
.page-content{
  padding-top: 6em;
  @include mq('tablet'){
      padding-top: 4em;
  }
}
.show-nav{
  .mobile-nav-btn{
    .fa-times{
      display: inline-block;
    }
    .fa-bars{
      display: none;
    }
  }
}
#privacy-tile, #terms-tile{
  font-size: 1.1rem;
  @include mq('tablet'){
    padding: 1.5rem;
  }

  ul, ol{
    margin: 2rem 0 2rem 5%;
      li{
          display: list-item;
          margin-bottom: 1rem;
          line-height: 1.5rem;
          &:last-child{
              margin-bottom: 0;
          }
          &>ol, &>ul{
            margin: 1rem 0 1rem 5%;
          }
      }
  }
  ul{
    li{
      list-style-type: disc;
    }
  }
  ol{
    counter-reset: item;
    &>li{
      list-style-type: none;
      margin-bottom: 1.5rem;
      counter-increment: item;
      h2{
        display: inline-block;
        margin-bottom: .5rem;
      }
      &:before {
        font-family: $headline;
        font-size: 1.75rem;
        margin-bottom: .75rem;
        line-height: 1.35rem;
        content: counter(item);
        border-radius: 100%;
        color: black;
        width: 1.5rem;
        display: inline-block;
      }
    }
    li{
      &>ol{
        li{
          list-style-type: lower-alpha;
          &:before{
            content: none;
            counter-increment: none;
          }
          &>ul{
            li{
              list-style-type: disc;
            }
          }
        }
      }
    }
  }
}
.mobile-nav-btn{
  cursor: pointer;
  color: $primary;
  font-size: 2.5em;
}
body{
  &.lock{
    overflow: hidden;
  }
}
sup{
  font-size: 50%;
  vertical-align: top;
  line-height: inherit;
}
#form-tile{
  background: darken(white, 7%);
  padding: 2rem;
  form{
    max-width: 600px;
  }
}
.intro{
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    .content-wrapper{
        text-align: center;
        max-width: 650px;
        h1{
          font-size: 1.75rem;
        }
    }
}
.about-banner, .clinic-banner, .coupon-banner{
  border-top: 1px solid darken(white, 20%);
  border-bottom: 1px solid darken(white, 20%);
  padding: 2rem;
  background-size: 35rem;
  min-height: 15rem;
  height: 15rem;
  background-position: left center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  h3{
    color: $primary;
  }
  .content-wrapper{
    margin-left: 250px;
  }
  @include mq('tablet-small'){
    .content-wrapper{
      margin-left: 150px;
      p{
        font-weight: 600;
      }
    }
    background-size: 30rem;
    // background-position-x: -3rem;
    padding: 1rem;
  }
  @include mq('phablet'){
    .content-wrapper{
      margin-left: 75px;
      p{
        font-weight: 600;
      }
    }
    // background-position-x: -5rem;
  }
  @include mq('phone-wide'){
    background-position-x: -4rem;
  }
  @include mq('phone'){
    padding: .5rem;
  }
}

.about-banner{
  background-image: url('/img/about-fade-2.png');
  background-position: left top;
  justify-content: left;
  .coupon-wrapper{
    background-image: url('/img/coupon-tile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 17rem;
    width: 17rem;
    margin-left: 3rem;
    // @include mq('tablet'){
    //   margin-left: 2rem;
    // }
    @include mq('tablet'){
      display: none;
    }
    @include mq('phablet'){
      display: flex;
      order: 1;
      height: 15rem;
      width: 15rem;
      margin-bottom: 1rem;
      margin-left: 0;
    }
  }
  .content-wrapper{
    max-width: 400px;
    margin-left: 275px;
    .btn.main{
      background: $primary;
    }
    h3{
      line-height: 1.75rem;
      color: black;
      margin-bottom: 1.5rem;
      .vanatol-s, .vanatol-lq{
        color: $primary;
      }
    }
  }
  @include mq('tablet'){
    .content-wrapper{
      margin-left: 250px;
    }
  }
  @include mq('tablet-small'){
    background-position-x: -4rem;
    .content-wrapper{
      margin-left: 200px;
    }
  }
  @include mq('phablet'){
    height: auto;
    flex-direction: column;
    background-position-x: -4rem;
    align-items: center;
    background: white;
    .content-wrapper{
      margin-left: 0;
      order: 2;
      text-align: center;
      width: 100%;
      max-width: none;
    }
  }
}
.clinic-banner{
  background-image: url('/img/clinic-fade.png');
  background-position: left center;
  @include mq('phone-wide'){
    background-position-x: -2rem;
  }
}
.coupon-banner{
  background-color: white;
  background-image: url('/img/coupon-fade.png');
  background-position: left center;
  background-position-x: -3rem;
  .content-wrapper{
    margin-left: 250px;
    max-width: 400px;
  }
  @include mq('tablet-small'){
    background-position-x: -6rem;
  }
  @include mq('phone-wide'){
    .content-wrapper{
      margin-left: 100px;
    }
  }
}
strong{
  em{
    font-weight: bold;
    font-style: italic;
  }
}
.legal-note{
  color: darken(white, 30%);
  font-size: 80%;
}
.sub-note{
  color: lighten(black, 50%);
  font-size: 1rem;
  line-height: 1.3rem;
}
.safety-wrapper{
  background: darken(white, 5%);
  border-top: 2px solid black;
  height: 6rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: z('site-header');
  display: inline-flex;
  align-items: center;
    // overflow-y: scroll;
  .expand-btn{
    .close{
      display: none;
    }
  }
  &.expanded{
    display: block;
    align-items: center;
    height: auto;
    .legal-footer{
      display: block;
      padding: 2.5rem;
      @include mq('tablet-small'){
        padding: 2.5rem 1rem;
      }
      // border-top: 2px solid black;
      position: relative;
      h4{
        margin-bottom: 1.5rem;
      }
      .expand-btn{
        position: absolute;
        top: 1rem;
        right: 2.5rem;
        @include mq('tablet-small'){
          right: 1rem;
        }
        .close{
          display: inline-block;
        }
        .open{
          display: none;
        }
      }
    }
    .content-wrapper{
      display: block;
      width: 100%;
      overflow-y: scroll;
      height: 50vh;
      h4{
        margin-bottom: .5rem;
        margin-top: 1rem;
      }
    }
  }
  .content-wrapper{
    display: none;
    height: 0;
  }
  //display: none;
  &.hide {
    visibility:hidden;
    z-index:-99;
  }
  .legal-footer{
    padding: 0 2.5rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    border: 0;
    h4{
      color: #e52515;
      margin-bottom: 0;
      font-family: $headline;
      text-transform: none;
      font-size: 1.5rem;
    }
    @include mq('tablet-small'){
      padding: 0 1rem;
    }
    @include mq('phablet'){
      h4{
        line-height: normal;
        // font-size: 85%;
      }
      .expand-btn{
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }
  }
  .expand-btn{
    color: $primary;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    // text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
    i{
      margin-left: .25rem;
      font-size: 1.15rem;
    }
  }
}
.inline-link{
  .nowrap{
    text-decoration: underline;
  }
}
.legal-footer{
  letter-spacing: 0;
  background: darken(white, 5%);
  border-top: 1px solid darken(white, 20%);
  color: black;
  padding: 2.5rem;
  .inline-link{
    text-decoration: underline;
  }
  @include mq('tablet-small'){
    // padding: 1em;
    padding: 2rem 1rem;
    font-size: 80%;
  }
  h4{
    // margin-bottom: .5rem;
    // margin-top: 1rem;
    color: $primary;
    // margin-bottom: 0;
    font-family: $headline;
    text-transform: none;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    display: inline-flex;
    margin-right: auto;
  }
  .content-wrapper{
    h4{
      margin-bottom: .5rem;
      margin-top: 1rem;
      color: black;
      // margin-bottom: 0;
      font-family: $headline;
      text-transform: none;
      font-size: 1.5rem;
    }
  }
  .footnotes{
    margin-bottom: 3em;
    font-size: 90%;
    // background: darken(white, 3%);
    // padding: .5rem;
    .inline-link{
      color: black;
      font-weight: normal;
      text-decoration: underline;
    }
    @include last-child('bottom', .5rem);
    sup{
      margin-right: .5rem;
      font-size: 80%;
    }
    li{
      @extend .indent;
      line-height: 1.25rem;
    }
  }
}
.contact-wrapper{
  .optional{
    padding: 2rem 1rem;
    border: 1px solid darken(white, 20%);
    margin-top: 1rem;
  }
  .info{
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 4rem;
  }
  form{
      text-align: center;
      textarea:focus, input:focus{
          @extend .card-shadow;
      }
  }
  .field{
      padding: 0;
      text-align: left;
      width: 100%;
      display: inline-block;
      float: left;
      label{
          font-size: 1.15rem;
      }
      &:first-of-type{
        padding-right: .75rem;
        width: 50%;
      }
      &:last-of-type{
        padding-left: .75rem;
        width: 50%;
      }
      &:only-of-type{
        width: 100%;
        padding: 0;
      }
      .field{
        width: 50%;
      }
  }
  input, textarea{
      font-size: 1.25rem;
  }
  button{
      margin: 0 auto;
      margin-top: 1rem;
  }
}
.anchor{
  display: block;
  height: 6rem;
  margin-top: -6rem;
  visibility: hidden;
}
.anchor:after{
  content: "";
  position: absolute;
  height: 6rem;
  width: 1px;
  top: -6rem;
  left: 0;
}
select{
  border-radius: 0;
  border: none;
  outline: 0;
  font-size: 1.25rem;
  width: 100%;
  padding: .35em;
  background: white;
  border: 1px solid darken(white, 15%);
  color: lighten(black, 40%);
  -webkit-appearance: none;
  background-image: url('/img/dropdown-icon.png');
  background-position: center right .5rem;
  background-repeat: no-repeat;
  option{
  }
}
footer{
  padding: 0;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // width: 100%;
  // height: 10rem;
  .legal-footer{
    .expand-btn{
      display: none;
    }
  }
  .heavy-footer{
    background: lighten(black, 20%);
    color: darken(white, 15%);
    padding: 1rem 2.5rem;
    nav{
      margin-top: 1rem;
      display: block;
      margin-bottom: 1.5rem;
    }
    address{
      margin-bottom: 2rem;
      line-height: 1.25rem;
      width: 100%;
        br{
            line-height: 1.5rem;
        }
    }
    .phone, .hours, .trademark{
      display: block;
      margin-bottom: 1rem;
      line-height: 1.5rem;
    }
    .info{
      display: block;
      margin: 1rem 0;
      line-height: 1.5rem;
    }
    .trademark{
      margin-top: 1rem;

    }
    .footer-logo{
        display: inline-flex;
        width: 7rem;
        margin-left: auto;
        flex-direction: column;

        img{
            width: 100%;
        }
    }
    .footer-info{
      display: inline-flex;
      width: 50%;
      font-size: 70%;
      flex-direction: column;
      @include mq('tablet'){
        width: 100%;
        display: block;
      }
    }
    .content-wrapper{
      display: flex;
      @include mq('tablet'){
        flex-direction: column;
        text-align: center;
      }
    }
    .site-info{
      font-size: 70%;
      width: 50%;
      display: inline-flex;
      flex-direction: column;
      text-align: right;
      span{
        display: block;
        line-height: 1.5rem;
        &.nowrap{
          display: inline-block;
        }
        &:last-child{
          margin-right: 0;
        }
      }
      @include mq('tablet'){
        width: 100%;
        display: block;
        text-align: center;
      }
    }
    @include mq('tablet-small'){
      padding: 2rem 1rem;
    }
    a{
      color: white;
    }
    nav{
      @include last-child('right', 2rem);
      ul li{
        text-transform: none;
      }
      @include mq('phablet'){
        @include last-child('bottom', 1.5rem);
        margin: 0;
        margin-top: 1em;
        width: 100%;
        margin-bottom: 1rem;
        ul li{
          margin-right: 0;

        }
      }
    }
  }
}
