#about{
    .coupon-banner{
        border: 0;
    }
    .cta-banner{
        background: $secondary;
        color: white;
        text-align: center;
        padding: 2rem 1rem;
        p{
            color: white;
            max-width: 400px;
            margin: 0 auto;
        }
        .btn.main{
            background: $primary;
            margin-top: 1rem;
        }
    }
    #product-tile{
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq('tablet'){
            padding: 1.5rem;
        }
        .content-wrapper{
            max-width: 990px;
            align-self: center;
        }
        .products{
            display: none;
            @include fixed-box(23rem, right);
            margin-left: 2rem;
            .product{
                background: darken(white, 25%);
                width: 10rem;
                height: 10rem;
                display: inline-block;
            }
            @include mq('tablet'){
                float: none;
                margin: 0 auto;
                margin-bottom: 1rem;
            }
        }
        .info{
            @include fluid-box();
            @include mq('tablet'){
                display: block;
                width: 100%;
            }
        }
    }
    #about-tile{
        background: darken(white, 5%);
        padding: 0;
        text-align: center;
        // @include mq('phablet'){
        //     padding: 3em 1em;
        // }
        @include mq('phone'){
            padding: 0;
        }
        .intro{
            // padding: 4rem;
            min-height: 350px;
            height: 350px;
            background-color: white;
            margin-bottom: 0;
            background-image: url('/img/about-hero.png');
            background-position: right;
            background-size: 100%;
            background-repeat: no-repeat;
            border-bottom: 1px solid darken(white, 10%);
            justify-content: flex-start;
            align-items: center;
            .content-wrapper{
                text-align: left;
                margin-left: 2rem;
            }
            @include mq('container'){
                font-size: 90%;
                .content-wrapper{
                    text-align: left;
                    margin-left: 1rem;
                }
            }
            @include mq('tablet-wide'){
                background-size: cover;
                .content-wrapper{
                    max-width: 400px;
                    margin-left: 1.5rem;
                }
            }
            @include mq('tablet'){
                padding-top: 16rem;
                background-position: top right;
                background-size: 46rem;
                height: auto;
                .content-wrapper{
                    align-self: flex-end;
                    width: 100%;
                    max-width: none;
                    background: white;
                    padding: 1.5rem;
                    margin-left: 0;
                }
            }
        }
        .row{
            display: flex;
            background: white;
            border-bottom: 1px solid darken(white, 20%);
            align-items: center;
            @include mq('container'){
                display: block;
            }
            .sizes{
                text-align: left;
                display: inline-flex;
                padding: 1.5rem;
                width: 50%;
                background: white;
                height: 100%;
                @include mq('tablet'){
                    width: 100%;
                    display: block;
                    float: none;
                }
            }
            .coupon-banner{
                float: left;
                width: 50%;
                display: inline-flex;
                border-right: 1px solid darken(white, 20%);
                text-align: left;
                @include mq('container'){
                    width: 100%;
                    border-right: 0;
                    border-bottom: 1px solid darken(white, 20%);
                }
                @include mq('tablet'){
                    float: none;
                }
            }
        }
    }
}
