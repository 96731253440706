#contact{
    #contact-tile{
        .info{
            text-align: center;
        }
        background: darken(white, 2%);
        @include mq('tablet-small'){
            padding: 2rem 1rem;
            .contact-wrapper{
                .field{
                    &:first-of-type{
                        width: 100%;
                        padding: 0;
                    }
                    &:last-of-type{
                        width: 100%;
                        padding: 0;
                    }
                }
            }
        }
    }
}
