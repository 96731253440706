@import url('https://fonts.googleapis.com/css?family=Cantarell:400,400i,700,700i|Fjalla+One');

// FONTS
$headline: 'Fjalla One', sans-serif;
$body: 'Cantarell', sans-serif;

$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "container":    1100px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);

$z-indexes: (
    "site-header",
    "mobile-nav",
    "site-wrapper"
);

// BRAND COLORS
$primary: #eb3829;
$primary-light: #f48066;
$secondary: #005f9e;
$secondary-light: #0090d1;

$footer-background: lighten(black, 20%);

// UTILITY COLORS
$error-color: #D8000C;
$error-background: #FFBABA;
$success-color: #4F8A10;
$success-background: #DFF2BF;

// SOCIAL MEDIA COLORS
$facebook: #3b5998;
$twitter: #00aced;
$instagram: #bc2a8d;
