#professionals{
    .top-btn{
        margin-top: 2rem;
        @include mq('tablet'){
            text-align: center;
        }
    }
    .info-menu{
        @include last-child('bottom', .5rem);
        display: inline-block;
        @include fixed-box(20rem, left);
        margin-right: 2rem;
        // position: -webkit-sticky;
        // position: sticky;
        ul{
            li{
                background: darken(white, 10%);
                a{
                    color: black;
                    display: block;
                    padding: .5rem 1rem;
                }
                &.active{
                    background: $primary;
                    a{
                        color: white;
                        &:hover{
                            color: white !important;
                        }
                    }
                }
            }
        }
        @include mq('tablet'){
            width: 100%;
            float: none;
        }
    }
    #fixed-menu{
        position: fixed;
        left: 3rem;
        top: 7rem;
        opacity: 0;
        z-index: -99999;
        .info-menu{
            display: inline-block;
        }
        @include mq('tablet-wide'){
            left: 1rem;
        }
        @include mq('tablet'){
            font-size: 80%;
            width: 100%;
            left: 1rem;
            margin-right: 1rem;
            top: 5rem;
            display: none;
            .info-menu{
                @include last-child('bottom', 1rem);
                display: block;
                width: 100%;
                // ul{
                //     li{
                //         display: none;
                //         &.active{
                //             display: block;
                //         }
                //     }
                // }
            }
        }
    }
    #tabs-tile{
        @include mq('tablet-wide'){
            padding: 3rem 1rem;
        }
        @include mq('tablet'){
            font-size: 80%;
            .info-menu{
                @include last-child('bottom', .5rem);
            }
        }
    }
    .tabs-wrapper{
        // position: relative;
        @include fluid-box();
        @include mq('tablet'){
            width: 100%;
            float: none;
        }
    }
    .tab-wrapper{
        display: none;
        .chemical-box{
            margin-bottom: 2rem;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        sub{
            font-size: 60%;
            vertical-align: bottom;
        }
        &.active{
            display: block;
        }
        margin-top: -.75rem;
        padding: 2rem;
        @include mq('phablet'){
            padding: 2rem 1rem;
        }
        border-left: 1px solid darken(white, 70%);
        // border-right: 1px solid darken(white, 70%);
        h3{
            margin-top: 2rem;
            font-size: 1.25rem;
        }
        h4{
            margin-top: 1.5rem;
            font-family: $body;
            font-weight: normal;
            text-transform: uppercase;
        }
        h5{
            margin-top: 1rem;
            font-size: 1.15rem;
        }
        ul{
            margin: 1rem 0;
            list-style-type: disc;
            list-style-position: inside;
            margin-left: 2rem;
            li{
                list-style-type: disc;
                display: list-item;
                margin-bottom: .5rem;
                line-height: 1.5rem;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        @include mq('tablet'){
            border-left: 0;
        }
    }

    #professionals-tile{
        padding: 0;
        .intro{
            min-height: 350px;
            height: 350px;
            background-color: white;
            margin-bottom: 0;
            display: flex;
            background-image: url('/img/professionals-hero.png');
            background-position: right;
            background-size: 100%;
            background-repeat: no-repeat;
            border-bottom: 1px solid darken(white, 10%);
            align-items: center;
            justify-content: flex-start;
            // display: none;
            .content-wrapper{
                text-align: left;
                margin-left: 2rem;
                max-width: 450px;
            }
            @include mq('container'){
                background-size: 65rem;
                background-position: top right;
                // height: auto;
                .content-wrapper{
                    margin: 0;
                    width: 100%;
                    padding: 1.5rem;
                    background: white;
                    align-self: flex-end;
                    max-width: none;
                    text-align: center;
                }
            }
            @include mq('tablet'){
                background-size: cover;
                .content-wrapper{
                    h2{
                      font-size: 1.25rem;
                    }
                }
            }
        }
    }

    .contact-wrapper{
        border-top: 1px solid darken(white, 40%);
        background-color: darken(white, 2.5%);
        .optional{
            position: relative;
            .option-label{
                top: -.75rem;
                left: 1rem;
                background-color: darken(white, 2.5%);
                padding: .25rem;
                position: absolute;
                text-transform: uppercase;
            }
            .field{
                margin-bottom: 0;
                .field{
                    margin-bottom: 0;
                    &:first-of-type{
                        width: 80%;
                        margin-bottom: 0;
                    }
                    &:last-of-type{
                        width: 20%;
                        margin-bottom: 0;
                    }
                }
                &:last-of-type{
                    margin-bottom: 0;
                }
                @include mq('container'){
                    width: 100%;
                    padding: 0;
                    margin-bottom: 1rem;
                    .field{
                        padding-left: .5em;
                        padding-right: .5em;
                        margin-bottom: 1rem;
                        &:first-of-type{
                            padding-left: 0;
                        }
                        &:last-of-type{
                            padding-right: 0;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    @include mq('tablet-small'){
        .contact-wrapper{
            padding: 2rem 1rem;
            .field{
                &:first-of-type{
                    width: 100%;
                    padding: 0;
                }
                &:last-of-type{
                    width: 100%;
                    padding: 0;
                }
                .field{
                    padding: 0;
                    &:first-of-type{
                        width: 100%;
                    }
                    &:last-of-type{
                        width: 100%;
                    }
                }
            }
            .optional{
                .field{
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    .field{
                        padding: 0;
                        margin-bottom: 1rem;
                        &:first-of-type{
                            width: 100%;
                            margin-bottom: 1rem;
                        }
                        &:last-of-type{
                            width: 100%;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
