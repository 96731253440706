#clinic{
    #headache-tile{
        background: darken(white, 5%);
        // padding: 3em 2em;
        padding: 0;
        .boxes-wrapper{
            display: flex;
            padding: 2rem;
            @include mq('tablet-small'){
                display: flex;
                flex-direction: column;
                padding: 1rem;
            }
        }
        @include mq('phablet'){
            // padding: 3em 1em;
        }
        @include mq('phone'){
            padding: 0;
        }
    }
    .s-box, .lq-box{
        letter-spacing: 0;
        width: 50%;
        display: flex;
        min-height: 20rem;
        &:first-of-type{
            padding-right: .75em;
        }
        &:last-of-type{
            padding-left: .75em;
        }
        .content-wrapper{
            background: white;
            border: 1px solid darken(white, 10%);
            padding: 2em;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            @extend .card-shadow;
            .btn{
                display: inline-flex;
            }
            .contents{
                max-width: 250px;
                img{
                    width: 100%;
                    margin-bottom: 1.5rem;
                }
                h2{
                    font-size: 1rem;
                    padding-left: .5rem;
                    a{
                        color: black;
                    }
                }
            }
        }
        @include mq('container'){
            .content-wrapper{
                .contents{
                    max-width: 160px;
                }
            }
        }
        @include mq('tablet-wide'){
            width: 100%;
            display: flex;
            // margin-bottom: 2em;
            &:first-of-type{
                // padding: 0;
            }
            &:last-of-type{
                // padding: 0;
                margin-bottom: 0;
            }
            .contents{
                margin-left: auto;
            }
        }
        @include mq('tablet'){
            .content-wrapper{
                .contents{
                    max-width: 130px;
                }
            }
        }
        @include mq('tablet-small'){
            margin-bottom: 1rem;
            &:first-of-type{
                padding: 0;
            }
            &:last-of-type{
                padding: 0;
            }
        }
        @include mq('phablet'){
            .content-wrapper{
                .contents{
                    max-width: 165px;
                }
            }
        }
        @include mq('phone'){
            .content-wrapper{
                .contents{
                    max-width: 125px;
                }
            }
        }
    }
    .s-box{
        .content-wrapper{
            background-image: url('/img/vanatol-s-bottle.png');
            background-position: left -3rem bottom;
            background-repeat: no-repeat;
            background-size: 16rem;
            padding-left: 50%;
            @include mq('tablet-wide'){
                background-size: 12rem;
                // padding-left: 11rem;
                background-position-y: center;
            }
        }
    }
    .lq-box{
        .content-wrapper{
            align-items: center;
            justify-content: center;
            padding-left: 20%;
            background-image: url('/img/vanatol-lq-bottle.png');
            background-repeat: no-repeat;
            background-size: 6rem;
            background-position: left 2rem center;
            background-position-y: 1rem;
            @include mq('tablet-wide'){
                padding-left: 7.5rem;
                background-size: 5.5rem;
                background-position-y: center;
            }
        }
    }
    .intro{
        background-color: white;
        display: flex;
        justify-content: flex-start;
        background-image: url('/img/clinic-hero.png');
        background-position: right;
        background-position-x: 6rem;
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 4rem;
        margin-bottom: 0;
        border-bottom: 1px solid lighten(black, 70%);
        h1{
            margin-bottom: 1rem;
        }
        .content-wrapper{
            text-align: left;
            max-width: 650px;
            margin-left: 2rem;
        }
        @include mq('tablet-wide'){
            padding: 4rem 2rem;
            background-size: cover;
            background-position: right -10rem center;
            .content-wrapper{
                margin-left: 0;
                max-width: 550px;
            }
        }
        @include mq('tablet'){
            .content-wrapper{
                h1{
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                }
                max-width: 400px;
            }
        }
        @include mq('tablet-small'){
            padding: 0;
            padding-top: 25rem;
            background-position: right -15rem center;
            .content-wrapper{
                background: white;
                padding: 1.5rem;
                max-width: none;
            }
        }
    }
}
