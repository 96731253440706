#home{
  #intro-tile{
    padding: 0;
    .lSSlideOuter, .lSSlideWrapper, #heroSlider, .lslide, .hero-block, .product-block{
      min-height: 25em;
      height: 25rem;
    }
    .lSSlideOuter{
      position: relative;
    }
    .lSPager{
      position: absolute;
      bottom: 1em;
      left: 1em;
    }
    .hero-block, .product-block{
      display: inline-block;
      float: left;
      .content-wrapper{
        max-width: 515px;
        margin: 1em;
        margin-left: 5rem;
        text-align: left;
      }
      @include mq('tablet-wide'){
          .content-wrapper{
              margin-left: 2rem;
              font-size: 95%;
          }
      }
    }
    .hero-block{
      width: 100%;
      background: white;
      #heroSlider{
          .btn{
              margin-top: 1.5rem;
          }
          li{
            display: flex;
            justify-content: left;
            align-items: center;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            text-align: center;
            height: 25rem;
            em{
              font-weight: bold;
              font-style: italic;
              color: $primary;
              margin-right: .25rem;
            }
            &.slide-1{
              background-position: right center;
              background-position-x: 10rem;
              background-image: url('/img/professionals-hero.png');
              .nowrap{
                color: $primary;
              }
            }
            // &.slide-2{
            //   background-image: url('/img/home-slide-10.png');
            // }
            // &.slide-3{
            //   background-position: top;
            //   background-image: url('/img/home-slide-3.png');
            // }
            @include mq('tablet'){
              background-size: 33rem;
              background-position: top right !important;
              .content-wrapper{
                margin: 0;
                font-size: 95%;
                max-width: none;
                width: 100%;
                padding: 1.5rem;
                background: white;
                align-self: flex-end;
                text-align: center;
                border-top: 1px solid darken(white, 15%);
                padding-bottom: 2.5rem;
                h2{
                  font-size: 1.25rem;
                }
              }
            }
            @include mq('tablet-small'){
              .content-wrapper{
                p{
                  font-size: 85%;
                }
              }
            }
            // &:nth-of-type(4){
            //   background-position: top;
            //   background-image: url('/img/hero-tile-11.png');
            // }
            // &:nth-of-type(5){
            //   background-position: top;
            //   background-image: url('/img/hero-tile-6.png');
          }
      }
      h2{
        font-size: 1.75em;
        margin-bottom: .25em;
      }
      p{
        font-size: 1.25em;
      }
    }
    .product-block{
      width: 41.66666666666667%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    }
    @include mq('tablet'){
      .hero-block, .product-block{
        width: 100%;
        .lSSlideOuter, .lSSlideWrapper, #heroSlider, .lslide, .hero-block, .product-block{
          // height: auto;
        }
      }
    }
  }
  #action-tiles{
    padding: 1.5rem;
    background: darken(white, 2%);
    border-bottom: 1px solid darken(white, 20%);
    border-top: 1px solid darken(white, 20%);
    .container{
      padding: 0;
      display: flex;
      @include mq('tablet-wide'){
        flex-direction: column;
      }
    }


  }
  .action-tile{
    width: 33.3333333%;
    display: flex;
    // flex: 1;
    // display: inherit;
    background-position: left;
    background-size: 30rem;
    padding: 1rem;
    background-repeat: no-repeat;
    .btn{
        display: inline-block;
        width: auto;
        flex-grow: 0;
        background: $primary;
        margin-top: auto;
        text-align: center;
        flex-grow: 0;
        &:hover{
          background: darken($primary, 5%);
        }
    }
    .card{
      background: white;
      @extend .card-shadow;
      border: 1px solid darken(white, 12%);
      padding: 1rem;
      display: flex;
      flex-direction: column;
      padding-bottom: 1.5rem;
    //   flex-grow: 1;
      flex: 1;
      h3{
          color: $primary;
          line-height: 1.8rem;
          min-height: 2.25em;
      }
    }
    .image{
      width: 100%;
      height: 15em;
      background-size: cover;
      background-position: center;
      border: 1px solid darken(white, 10%);
    }
    &.about{
      background-size: 28em;
      background-position: top left;
      .image{
        background-image: url('/img/about-tile-2.jpg');
      }
    }
    &.clinic{
      .image{
        background-image: url('/img/clinic-tile-2.jpg');
      }
    }
    &.coupon{
      .image{
        background-image: url('/img/coupon-tile.png');
      }
    }
    p{
      min-height: 4.5em;
    }
    &:last-child{
      // border-right: 0;
    }
    .info-wrapper{
      padding: 1.75rem .5rem;
      text-align: center;
    }
    @include mq('tablet-wide'){
      width: 100%;
      // flex: 1 100%;
      border-right: 0;
      border: 1px solid darken(white, 20%);
      padding: 0;
      background-color: white;
      margin-bottom: 1.5rem;
      box-shadow: 0 4px 2px -2px rgba(0,0,0,0.2);
      .card{
        background: none;
        border: 0;
        box-shadow: none;
        .btn{
          display: inline-block;
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
      .image{
        display: none;
      }
      &.coupon{
        background-image: url('/img/coupon-fade.png');
      }
      &.clinic{
        background-image: url('/img/clinic-fade.png');
      }
      &.about{
        background-image: url('/img/about-fade-2.png');
      }
      padding-left: 250px;
      .info-wrapper{
        background: none;
      }
    }
    @include mq('tablet-small'){
      padding-left: 0;
      .image{
        display: block;
      }
      background-image: none !important;
      background-color: white;
    }
  }
}
