#coupon{
    #coupon-tile{
        background: darken(white, 2%);
        padding: 3rem 2rem;
        display: flex;
        justify-content: center;
        @include mq('phablet'){
            padding: 3em 1em;
        }
        @include mq('phone'){
            padding: 0;
        }
    }
    .preview-wrapper{
        @include fixed-box(26rem, left);
        margin-right: 6rem;
        img{
            width: 100%;
        }
        @include mq('tablet-wide'){
            @include fixed-box(20rem, left);
            margin-left: 2rem;
            margin-right: 2rem;
        }
        @include mq('tablet'){
            @include fixed-box(14rem, left);
            margin-left: 0;
            margin-right: 1rem;
        }
        @include mq('phablet'){
            margin-left: 0;
            display: block;
            position: relative;
            width: 100%;
            // max-width: 400px;
            margin: 0 auto;
            margin-bottom: 2rem;
        }
    }
    .info-wrapper{
        @include fluid-box();
        text-align: left;
        .info{
            margin-bottom: 2rem;
            text-align: left;
        }
        @include mq('phablet'){
            width: 100%;
            display: block;
        }

    }
    .form-wrapper{
        max-width: 500px;
        @include mq('phablet'){
            max-width: none;
        }
        margin: 0 auto;
        margin: 0;
        form{
            text-align: center;
            textarea:focus, input:focus{
                @extend .card-shadow;
            }
        }
        .field{
            padding: 0;
            text-align: left;
            label{
                font-size: 1.15rem;
            }
        }
        input{
            font-size: 1.25rem;
        }
        button{
            margin: 0 auto;
            margin-top: 1rem;
        }
    }
}
